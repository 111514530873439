import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be90f500"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "default-state"
}
const _hoisted_2 = {
  key: 0,
  class: "actions"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "empty-state" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(['sidebar-resizer', $setup.sidebar_resizing ? 'resizing' : '']),
      onMousedown: $setup.start_resize
    }, null, 34 /* CLASS, NEED_HYDRATION */),
    _createElementVNode("div", {
      class: "sidebar-container",
      style: _normalizeStyle({ width: `${$setup.sidebar_width}px` })
    }, [
      ($setup.store.form.selected_field)
        ? (_openBlock(), _createBlock($setup["FieldProperties"], { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            ($setup.store.form.layout.tabs.length == 1 && !$setup.store.read_only && !$setup.store.doc.istable)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("button", {
                    class: "new-tab-btn btn btn-default btn-xs",
                    title: _ctx.__('Add new tab'),
                    onClick: _cache[0] || (_cache[0] = (...args) => ($setup.store.add_new_tab && $setup.store.add_new_tab(...args)))
                  }, _toDisplayString(_ctx.__("Add tab")), 9 /* TEXT, PROPS */, _hoisted_3)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, _toDisplayString(_ctx.__("Select a field to edit its properties.")), 1 /* TEXT */)
            ])
          ]))
    ], 4 /* STYLE */)
  ], 64 /* STABLE_FRAGMENT */))
}