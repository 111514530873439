import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb973a79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-box" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "search-icon" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "search-input form-control",
      type: "text",
      placeholder: _ctx.__('Search properties...'),
      onInput: _cache[0] || (_cache[0] = (event) => _ctx.$emit('update:modelValue', event.target.value))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
    _createElementVNode("span", _hoisted_3, [
      _createElementVNode("div", {
        innerHTML: _ctx.frappe.utils.icon('search', 'sm')
      }, null, 8 /* PROPS */, _hoisted_4)
    ])
  ]))
}