import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SelectControl = _resolveComponent("SelectControl")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SelectControl, {
      df: $setup.doctype_df,
      value: $setup.doctype,
      read_only: $props.read_only,
      modelValue: $setup.doctype,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.doctype) = $event))
    }, null, 8 /* PROPS */, ["df", "value", "read_only", "modelValue"]),
    ($setup.doctype)
      ? (_openBlock(), _createBlock(_component_SelectControl, {
          key: 0,
          df: $setup.field_df,
          read_only: $props.read_only,
          value: $setup.fieldname,
          modelValue: $setup.fieldname,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.fieldname) = $event)),
          no_label: true
        }, null, 8 /* PROPS */, ["df", "read_only", "value", "modelValue"]))
      : _createCommentVNode("v-if", true)
  ]))
}