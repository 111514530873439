import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-180dc7db"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title", "onClick"]
const _hoisted_2 = {
  class: "autocomplete",
  ref: "autocomplete_ref"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("button", {
    ref: "add_field_btn_ref",
    class: "add-field-btn btn btn-xs btn-icon",
    title: $props.tooltip,
    onClick: _withModifiers($setup.toggle_fieldtype_options, ["stop"])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.__("Add field")), 1 /* TEXT */)
    ], true),
    (_openBlock(), _createBlock(_Teleport, { to: "#autocomplete-area" }, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("div", null, [
          _createVNode($setup["Autocomplete"], {
            show: $setup.show,
            "onUpdate:show": _cache[0] || (_cache[0] = $event => (($setup.show) = $event)),
            value: $setup.autocomplete_value,
            options: $setup.fields,
            onChange: $setup.add_new_field,
            placeholder: _ctx.__('Search fieldtypes...')
          }, null, 8 /* PROPS */, ["show", "value", "options", "placeholder"])
        ], 512 /* NEED_PATCH */), [
          [_vShow, $setup.show]
        ])
      ], 512 /* NEED_PATCH */)
    ]))
  ], 8 /* PROPS */, _hoisted_1))
}